import { render, staticRenderFns } from "./SalesAfterRequest.vue?vue&type=template&id=c4f62c46&scoped=true"
import script from "./SalesAfterRequest.vue?vue&type=script&lang=js"
export * from "./SalesAfterRequest.vue?vue&type=script&lang=js"
import style0 from "./SalesAfterRequest.vue?vue&type=style&index=0&id=c4f62c46&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c4f62c46",
  null
  
)

export default component.exports